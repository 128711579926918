<template>
  <div class="footer">
    <div class="foo-top">
      <div class="foo-toplogo"></div>
      <div class="foo-topright">
        <div class="wechat">
          <div class="wechatimg"></div>
        </div>
        <div class="totop" @click="toTop"></div>
      </div>
    </div>
    <div class="foo-bottom">
      <div class="left">
        <div class="nav">
          <template v-for="nav in navList">
            <router-link v-if="nav.id != 3" :to="`${nav.link}`">{{ nav.name }}</router-link>
          </template>
        </div>
        <div class="record">
          <div>备案号：京ICP备2024088222号-1</div>
          <div>© 2024 FUTURE CITIZEN BUREAU 保留所有权利</div>
        </div>
      </div>
      <div class="right">
        <div class="f18px999">办公地址</div>
        <div class="f18pxfff">{{ $t("address") }}</div>
        <div class="f18px999">电子邮箱</div>
        <div class="f18pxfff">info@engglobal.info</div>
      </div>
    </div>
  </div>
</template>
<script>
import { GetNav } from "@/api/api";
export default {
  name: "FooterView",
  data() {
    return {
      navList: [],
    };
  },
  methods: {
    toTop() {
      // window.scrollTo(0, 0);
      document.documentElement.scrollTop = 0;
    },
  },
  created() {
    GetNav().then((res) => {
      if (res.data.code == 1) {
        this.navList = res.data.data;
        // localStorage.setItem('navList',JSON.stringify(this.navList))
      }
    });
  },
};
</script>
<style scoped>
.footer {
  width: 100%;
}
.foo-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 100px 8.33vw 40px 8.33vw;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.04);
}
.foo-toplogo {
  width: 189px;
  height: 77px;
  background: url(@/assets/footer-logo.png) no-repeat;
  background-size: cover;
}
.foo-topright {
  display: flex;
}
.wechat {
  width: 24px;
  height: 24px;
  background: url(@/assets/icon-wechat.png) no-repeat;
  background-size: cover;
  margin-right: 40px;
  position: relative;
  cursor: pointer;
}
.wechat:hover .wechatimg {
  width: 80px;
  height: 87px;
  /* background: purple; */
  background: url(@/assets/footer-code.png) no-repeat;
  background-size: 200%;
  background-position: center;
  position: absolute;
  top: 35px;
  left: -30px;
  opacity: 1;
}
.totop {
  width: 24px;
  height: 24px;
  background: url(@/assets/icon-totop.png) no-repeat;
  background-size: cover;
  cursor: pointer;
}
.foo-bottom {
  width: 100%;
  background: #405161;
  padding: 40px 8.33vw;
  display: flex;
  justify-content: space-between;
}
.foo-bottom .left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.foo-bottom .left .nav > * {
  font-size: 18px;
  color: #ffffff;
  line-height: 18px;
  padding-right: 60px;
}
.foo-bottom .left .record > * {
  font-size: 14px;
  color: #999999;
  line-height: 14px;
  padding-top: 10px;
}
.foo-bottom .right > * {
  text-align: right !important;
}
.foo-bottom .right .f18px999 {
  padding-bottom: 10px;
}
.foo-bottom .right .f18pxfff {
  padding-bottom: 40px;
}
</style>
