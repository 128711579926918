<template>
  <div>
    <div class="navSlo">
      <div class="navSlo-left">携手共创丨拥抱全球化机遇</div>
      <div class="navSlo-right">
        <div class="f14px1E2">
<!--          <img-->
<!--            src="@/assets/gb.svg"-->
<!--            style="width: 18px; height: 14px; margin-right: 10px"-->
<!--          />-->
<!--          <span @click="changeLanguage(0)">EN</span>&ensp;|&ensp;-->
          <img
            src="@/assets/cn.svg"
            style="width: 18px; height: 14px; margin-right: 10px"
          />
          <span class="on" @click="changeLanguage(1)">CN</span>
        </div>
        <div class="navSlo-right-cont">
          <i></i
          ><span class="f14px1E2" @click="showModal = true">{{
            $t("contactus")
          }}</span>
        </div>
      </div>
    </div>
    <div class="navLogo">
      <template v-for="(nav, key) in navList">
        <router-link v-if="nav.id == 3" to="/" class="logo"
          ><img src="@/assets/logo.png" alt=""
        /></router-link>
        <router-link
          v-else
          :to="`${nav.link}`"
          class="f18pxfff on"
          style="line-height: 18px"
          >{{ nav.name }}</router-link
        >
      </template>
      <!--      <router-link to="/" class="f14pxfff on">首页</router-link>-->
      <!--      <router-link to="/project" class="f14pxfff">服务项目</router-link>-->
      <!--      <router-link to="/" class="logo"-->
      <!--        ><img src="@/assets/logo.png" alt=""-->
      <!--      /></router-link>-->
      <!--      <router-link to="/news" class="f14pxfff">新闻资讯</router-link>-->
      <!--      <router-link to="/aboutus" class="f14pxfff">关于我们</router-link>-->
    </div>
    <Dialog :visible.sync="showModal"></Dialog>
  </div>
</template>
<script>
import "@/style/common.css";
import Dialog from "@/components/Dialog.vue";
import $ from "jquery";
import { GetNav } from "@/api/api";
export default {
  name: "Navbar",
  data() {
    return {
      showModal: false,
      navList: [],
    };
  },
  components: {
    Dialog,
  },
  created() {
    GetNav().then((res) => {
      if (res.data.code == 1) {
        this.navList = res.data.data;
      }
    });
  },
  methods: {
    changeLanguage(_index) {
      $(".navSlo-right span:eq('" + _index + "')")
        .addClass("on")
        .siblings("span")
        .removeClass("on");
      const lang = _index == 0 ? "en-US" : "zh-CN";
      this.$i18n.locale = lang;
      localStorage.setItem("language", lang);
      location.reload();
    },
  },
  mounted() {
    // 监听事件
    window.addEventListener("scroll", function () {
      let t = $("body, html").scrollTop(); // 目前监听的是整个body的滚动条距离
      if (t > 0) {
        $(".navLogo").addClass("navbar-active");
      } else {
        $(".navLogo").removeClass("navbar-active");
      }
    });
    $(function (options) {
      if (localStorage.getItem("language") == "zh-CN") {
        $(".navSlo-right div span:eq(1)")
          .addClass("on")
          .siblings("span")
          .removeClass("on");
      } else {
        $(".navSlo-right div span:eq(0)")
          .addClass("on")
          .siblings("span")
          .removeClass("on");
      }
      $(".navLogo .f14pxfff").click(function (e) {
        // $(this).addClass("on").siblings(".f14pxfff").removeClass("on");
      });
    });
  },
};
</script>
<style scoped>
.navSlo {
  width: 100%;
  height: 40px;
  background: #f7f7f7;
  display: flex;
  justify-content: space-between;
  padding: 0 8.33vw;
}
.navSlo-left {
  font-weight: 400;
  font-size: 14px;
  line-height: 40px;
  color: #405161;
}
.navSlo-right,
.navSlo-right .f14px1E2,
.navSlo-right .navSlo-right-cont {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.navSlo-right > .f14px1E2 {
  color: #999999;
}
.navSlo-right .navSlo-right-cont i {
  display: inline-block;
  /* background: #383a49; */
  background: url(@/assets/icon-earth1.png) no-repeat;
  background-size: cover;
  background-position: center;
  width: 12.5px;
  height: 12.5px;
  margin-left: 43px;
  margin-right: 10px;
}
.navSlo-right span.on {
  color: #405161;
}
.navLogo {
  width: 100%;
  height: 60px;
  background: #fff;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.08);
  /* padding: 0 32.55%; */
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1001;
}
.navLogo a {
  margin-right: 80px;
}
.navLogo a:last-of-type {
  margin-right: 0px;
}

.navLogo .f18pxfff {
  color: #000;
  height: 60px;
  line-height: 60px !important;
}
.navLogo .f18pxfff:hover {
  color: #405161 !important;
  border-bottom: 1px solid #405161;
}
.navLogo .logo img {
  width: 98px;
  height: 40px;
  object-fit: cover;
}
.navbar-active {
  position: fixed;
  top: 0;
  left: 0;
}
</style>
